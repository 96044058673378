
import moment from 'moment';
import React, { useState, useEffect } from "react";
import './Requests.css';


function Requests({ socket }) {
  const
    [_requestData, setButtonData] = useState(null),
    [_blink, setBlink] = useState(true),
    [time, setTime] = useState(Date.now()),
    parseWaStatus = status => {
      let
        result;
      //https://www.twilio.com/docs/sms/api/message-resource#message-status-values
      switch (status) {
        case 'new':
        case 'accepted':
          result = 'creat';
          break;
        case 'queued':
          result = 'in asteptare';
          break;
        case 'sending':
          result = 'in curs de trimitere';
          break;
        case 'sent':
          result = 'trimis';
          break;
        case 'failed':
          result = 'esuat';
          break;
        case 'delivered':
          result = 'livrat';
          break;
        case 'undelivered':
          result = 'nelivrat';
          break;
        case 'receiving':
          result = 'in curs de primire';
          break;
        case 'received':
          result = 'primit';
          break;
        case 'read':
          result = 'citit';
          break;
        case 'canceled':
          result = 'anulat';
          break;
        default:
          result = status;
          break;
      }
      return result;
    },
    parseWaReply = body => {
      let
        result;
      switch (body) {
        case 'Accept':
          result = 'Acceptat';
          break;
        case 'Resping':
          result = 'Respins';
          break;
        case 'Amân':
          result = 'Amânat';
          break;
        default:
          result = body;
          break;
      }
      return result;
    },
    formatElapsed = time => {
      let
        start = moment(time, 'YYYY-MM-DD HH:mm:ss.SSS Z'),
        end = moment(),
        elapsed = end.diff(start, 'hours'),
        unit = elapsed === 1 ? 'ora' : 'ore';

      if (elapsed === 0) {
        elapsed = end.diff(start, 'minutes');
        unit = elapsed === 1 ? 'minut' : 'minute';

        if (elapsed === 0) {
          elapsed = end.diff(start, 'seconds');
          unit = elapsed === 1 ? 'secunda' : 'secunde';

          if (elapsed <= 0) {
            return 'acum';
          }
        }
      }

      return `acum ${elapsed} ${unit}`;
    };


  useEffect(() => {
    socket.on('data', (data, fn) => {
      let
        shouldBlink = data && data.waReplyStatus && ['Accept', 'Resping'].includes(data.waReplyStatus.status) ? false : true;

      setButtonData(data || null);
      setBlink(shouldBlink);
      fn('ack');
    });

    // Remove event listener on component unmount
    return () => socket.off('data');
  }, [socket]);

  useEffect(() => {
    socket.emit('sendData');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      return setTime(Date.now());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    < div className="req" >
      {
        _requestData ? (
          <React.Fragment >
            <div className="req-header img"></div>
            <div className={`req-header orange ${_blink ? 'blink' : ''}`}>ALERTA</div>
            <div className='req-subheader' dangerouslySetInnerHTML={{ __html: _requestData.message }}></div>
          </React.Fragment >
        ) : (
          <React.Fragment>
            <div className="req-header img"></div>
            <div className="req-header orange">SOLICITARI</div>
            <div className='req-subheader'>
              Nu exista solicitari noi.
            </div>
          </React.Fragment>
        )
      }

      <div className='req-content'>
        {
          _requestData ? (
            <code>
              <div className='list'>
                <div className='list-row'>
                  <div className='label'>status</div><div className='value'>Mesaj WhatsApp {_requestData.waMessageStatus && _requestData.waMessageStatus.status && _requestData.waMessageStatus.dateUpdated ? `${parseWaStatus(_requestData.waMessageStatus.status)} (${formatElapsed(_requestData.waMessageStatus.dateUpdated)}).` : 'invalid'}</div>
                </div>
                <div className='list-row'>
                  <div className='label'></div><div className='value'>{_requestData.waReplyStatus && _requestData.waReplyStatus.status && _requestData.waReplyStatus.dateUpdated ? (`${parseWaReply(_requestData.waReplyStatus.status)} (${formatElapsed(_requestData.waReplyStatus.dateUpdated)}).`) : ''}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>---</div><div className='value'></div>
                </div>
                <div className='list-row'>
                  <div className='label'>reqid</div><div className='value'>{_requestData.requestId ? `${_requestData.requestId}` : '-'}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>sid</div><div className='value'>{_requestData.waMessageStatus ? `${_requestData.waMessageStatus.sid}` : ''}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>---</div><div className='value'></div>
                </div>
                <div className='list-row'>
                  <div className='label'>baddr</div><div className='value'>{_requestData.buttonData.baddr}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>serialNumber</div><div className='value'>{_requestData.buttonData.serialNumber}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>color</div><div className='value'>{_requestData.buttonData.color}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>name</div><div className='value'>{_requestData.buttonData.name}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>activeDisconnect</div><div className='value'>{_requestData.buttonData.activeDisconnect ? 'true' : 'false'}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>connected</div><div className='value'>{_requestData.buttonData.connected ? 'true' : 'false'}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>ready</div><div className='value'>{_requestData.buttonData.ready ? 'true' : 'false'}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>batteryStatus</div><div className='value'>{_requestData.buttonData.batteryStatus}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>uuid</div><div className='value'>{_requestData.buttonData.uuid}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>flicVersion</div><div className='value'>{_requestData.buttonData.flicVersion}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>firmwareVersion</div><div className='value'>{_requestData.buttonData.firmwareVersion}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>key</div><div className='value'>{_requestData.buttonData.key}</div>
                </div>
                <div className='list-row'>
                  <div className='label'>passiveMode</div><div className='value'>{_requestData.buttonData.passiveMode ? 'true' : 'false'}</div>
                </div>
              </div>
            </code>
          ) : null
        }
      </div >
    </div >
  );
}

export default Requests;