import io from 'socket.io-client';

import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Requests from './pages/Requests';


//https://socket.io/docs/v4/client-options/
const socket = io.connect(process.env.NODE_ENV === 'production' ? 'https://api.smart-connect.io' : 'http://localhost', {
  //secure: true,
  rejectUnauthorized: false,
  transports: ["websocket"]
});

function App() {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/requests" element={<Requests socket={socket} />} />
        </Routes>
      </main>
    </div>
  );
}



export default App;
